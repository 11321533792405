// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAfBCYqCAdde8t1EJ2XV7xEZx0g3TTcjAA',
    authDomain: 'baasi-dev.firebaseapp.com',
    databaseURL: 'https://baasi-dev.firebaseio.com',
    projectId: 'baasi-dev',
    storageBucket: 'baasi-dev.appspot.com',
    messagingSenderId: '57293474987',
    appId: '1:57293474987:web:1b868cc836daa06eb33bee',
    measurementId: 'G-8GMX64KQ2M',
  },
  googleMapsApiKey: 'AIzaSyAfBCYqCAdde8t1EJ2XV7xEZx0g3TTcjAA', // TODO: update to prod project key
  useEmulators: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
