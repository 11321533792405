import { enableProdMode, importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import {
  ScreenTrackingService,
  UserTrackingService,
  AngularFireAnalyticsModule,
} from '@angular/fire/compat/analytics';
import {
  PerformanceMonitoringService,
  AngularFirePerformanceModule,
} from '@angular/fire/compat/performance';
import { provideFirestore } from '@angular/fire/firestore';

import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { environment } from './environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore } from '@firebase/firestore';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

if (environment.production) {
  enableProdMode();
}

if (window && environment.production) {
  window.console.log =
    window.console.warn =
    window.console.info =
      () => {
        // Don't log anything on prod
      };
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      RouterModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAnalyticsModule,
      AngularFireAuthModule,
      AngularFirestoreModule,
      AngularFireFunctionsModule,
      AngularFirePerformanceModule,
      AngularFireStorageModule,
    ),

    // provide modular style for AppCheck, etc
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideCharts(withDefaultRegisterables()),
    provideHotToastConfig({
      autoClose: true,
      dismissible: true,
    }),
    PerformanceMonitoringService,
    ScreenTrackingService,
    UserTrackingService,
  ],
}).catch((err) => console.error(err));
